.emergency-button {
    color: white;
    background: #FF5733;
    border: #FF5733;
    box-shadow: 3px 3px 10px 0 #FF5733 !important;
    border-radius: 50% !important;
    transition: all 0s !important;
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 1000;
    width: 60px;
    height: 60px;
    font-size: 18px;
    outline: none !important;
}

.header-wrapper {
	display:flex;
	justify-content:space-between;
	padding-bottom:5px;
}

.panel-react-table .ReactTable .dropdown-menu.show{
    right: unset !important;
}

.dropdown-menu {
    min-width: 12rem;
}

@media only screen and (min-width: 992px) {
    .ReactTable {
        /* overflow: visible !important; */
    }

    .ReactTable .table-responsive {
        /* overflow-x: auto !important; */
    }
}

.clickable-link {
    color: #0078ff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}

.clickable-link:hover {
    text-decoration: underline;
}

.sidebar, .shop-layout .sidebar {
    background-color: white !important;
}

.sidebar .nav {
    background: white;
}

@keyframes heartbeat
{
  0%
  {
    transform: scale( .75 );
  }
  20%
  {
    transform: scale( 1 );
  }
  40%
  {
    transform: scale( .75 );
  }
  60%
  {
    transform: scale( 1 );
  }
  80%
  {
    transform: scale( .75 );
  }
  100%
  {
    transform: scale( .75 );
  }
}

.shop-layout .notification-indicator {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #e10a0a;
    border-radius: 50px;
    right: 15px;
    top: 16px;
    transform: translateY(-5px);
    animation: heartbeat 1s infinite;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-blue {
  color: #0078ff;
}

.text-red {
  color: #e10a0a;
}

.text-default {
  color: #1f2225;
}

.btn-group-selection .btn.active {
  font-weight: bold;
  background-color: #0078ff !important;
  color: white !important;
}

.eghl-service-col {
  text-align: center;
}

button.btn.btn-eghl-service {
  margin-bottom: 10px;
}

button.btn.btn-eghl-service img {
  width: 100%;
  margin-bottom: 10px;
}

.service-tab button.btn {
  min-width: 100px;
  border-radius: 15px;
  font-size: 18px;
  margin: 0 8px;
  padding: 11px 15px;
}

.service-tab button.btn.active, .service-amount-list button.btn.active {
  background-color: #32a932 !important;
  color: white !important;
  border-color: #32a932 !important;
}

.service-tab {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 25px;
}

.service-amount-list button.btn {
  margin: 8px;
  padding: 10px;
  min-width: 120px;
  border-radius: 35px;
  height: 120px;
  font-size: 20px;
}

.service-header {
  text-align: center;
  margin-bottom: 35px;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-header img {
  width: 100%;
  max-width: 70px;
}

.service-header h1 {
  display: inline-block;
  margin-bottom: 0px !important;
  margin-left: 5px;
  font-size: 22px;
}

.service-amount-list, .service-tab-content {
  text-align: center;
}

.service-tab-content button.btn.btn-lime {
  padding: 10px 35px;
  font-size: 18px;
  border-radius: 5px;
  margin-top: 35px;
}

.eghl-header {
  font-size: 28px;
  margin: 0 0 20px;
  padding: 0;
  border: none;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eghl-header span {
  font-size: 20px;
}